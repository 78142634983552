import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { EduFormFetch } from "../../fetch/eduFetch";
function EduForm() {
  const { course } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");

  const handleSummit = async (e) => {
    console.log("st");
    e.preventDefault();
    try {
      const responseData = await EduFormFetch(
        course,
        name,
        email,
        number,
        city
      );
      console.log(responseData.status);
      if (responseData.status === 200) {
        alert("Enrolled Successfully");
      }
      setCity("");
      setEmail("");
      setNumber("");
      setName("");
      return;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-gradient-to-r from-gray-100 to-gray-100 h-screen">
      {/* <h2>EduForm</h2> */}
      {/* <p>Course: {course}</p> */}
      {/* form  */}
      <div className="flex items-center justify-center h-screen">
        <form className="m-auto text-center md:w-1/2 bg-gradient-to-r from-gray-900 to-gray-900 border-2 border-transparent border-gradient-purple-600 p-8  text-white font-medium rounded-xl">
          <div className="p-4">
            <div className="text-white text-2xl font-poppins">
              Enrolment for {course}
            </div>
          </div>
          <div className="p-4">
            <label className="text-gray-400 font-poppins">Full Name</label>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2  mt-2 rounded border border-gray-400 bg-transparent text-white focus:outline-none focus:border-purple-600"
              required
            />
          </div>
          <div className="p-2">
            <label htmlFor="email" className="text-gray-400 font-poppins">
              Email
            </label>
            <input
              type="text"
              className="w-full px-4 py-2  mt-2 rounded border border-gray-400 bg-transparent text-white focus:outline-none focus:border-purple-600"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="p-2">
            <label htmlFor="number" className="text-gray-400 font-poppins">
              Phone Number
            </label>
            <input
              type="text"
              className="w-full px-4 py-2  mt-2 rounded border border-gray-400 bg-transparent text-white focus:outline-none focus:border-purple-600"
              required
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div className="p-2">
            <label htmlFor="text" className="text-gray-400 font-poppins">
              City
            </label>
            <input
              type="text"
              className="w-full px-4 py-2  mt-2 rounded border border-gray-400 bg-transparent text-white focus:outline-none focus:border-purple-600"
              required
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <button
            type="submit"
            onClick={handleSummit}
            className="mt-4 bg-gray-200 border border-gray-400 rounded px-20 py-2 text-black font-semibold hover:bg-white hover:text-gray-800 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default EduForm;
