import React, { useEffect, useState } from "react";
import { EduAdminOrderFetch } from "../../fetch/eduFetch";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/eduAdmin/eduAdminSlice";

function AdminEduDash() {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.eduAdmin.isLoggedIn);

  const [orders, setOrders] = useState([]);

  const fetchOrders = async () => {
    try {
      const response = await EduAdminOrderFetch();
      console.log(response.data);
      if (response.success) {
        setOrders(response.data);
      } else {
        console.error("Failed to fetch orders:", response.error);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      {loggedIn ? (
        <>
          <div className="flex justify-between">
            <div className="text-xl font-bold mb-4">Orders</div>
            <div className="text-xl font-bold mb-4" onClick={handleLogout}>
              Logout
            </div>
          </div>
          <ul>
            {orders.map((order) => (
              <li key={order._id} className="bg-gray-100 rounded-lg p-4 mb-4">
                <p className="font-semibold">Course: {order.course}</p>
                <p>Name: {order.name}</p>
                <p>Email: {order.email}</p>
                <p>Number: {order.number}</p>
                <p>City: {order.city}</p>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <div>Please Login to see this page</div>
        </>
      )}
    </div>
  );
}

export default AdminEduDash;
