const URL = "https://gotanbackend.digistall.in";

const EduFormFetch = async (course, name, email, number, city) => {
  try {
    console.log("st2");
    const response = await fetch(`${URL}/api/education/addform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ course, name, email, number, city }),
    });
    return response;
  } catch (error) {
    console.log(error, "error");
  }
};

const EduAdminLoginFetch = async (username, password) => {
  try {
    const response = await fetch(`${URL}/api/education/adminlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error("Failed to login");
    }
    return responseData;
  } catch (error) {
    console.error("Error logging in:", error.message);
  }
};

const EduAdminOrderFetch = async () => {
  try {
    const response = await fetch(`${URL}/api/education/allorder`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Order Error:", error.message);
    throw error;
  }
};

module.exports = {
  EduFormFetch,
  EduAdminLoginFetch,
  EduAdminOrderFetch,
};
