import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//@others
import { generateToken, messaging } from "./firebase.config";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import ErrorPage from "./utils/ErrorPage";

//@ Education
import Education from "./service/Education/education";
import EduForm from "./service/Education/eduform";
import AdminEduLogin from "./service/Education/adminlogin";
import AdminEduDash from "./service/Education/adminDash";
//@ Main
const HomeLayout = React.lazy(() => import("./Pages/HomePage/layout"));
const Login = React.lazy(() => import("./authentication/login"));
const Signup = React.lazy(() => import("./authentication/signup"));
const JodhpurAdminLogin = React.lazy(() =>
  import("./admin/components/adminLogin")
);
const AdminDashboard = React.lazy(() =>
  import("./admin/components/adminDashboard")
);
const AcceptedOrder = React.lazy(() => import("./admin/acceptedOrders/layout"));
const Shop = React.lazy(() => import("./admin/shops/layout"));

const LoginwithPassword = React.lazy(() =>
  import("./authentication/loginwithpass")
);
const ShopOrders = React.lazy(() => import("./admin/shops/shopOrders"));
const BookRider = React.lazy(() => import("./service/bookRider/bookrider"));
const BookParcel = React.lazy(() => import("./service/bookParcel/bookParcel"));
const Profile = React.lazy(() => import("./Pages/Profile/profile"));
const ProfilePassword = React.lazy(() => import("./Pages/Profile/password"));
const RiderHistory = React.lazy(() => import("./Pages/History/riderHistory"));
const ParcelHistory = React.lazy(() => import("./Pages/History/parcelHistory"));
const RiderViewMore = React.lazy(() => import("./Pages/History/riderViewMore"));
const ParcelViewMore = React.lazy(() =>
  import("./Pages/History/parcelViewMore")
);

function App() {
  onMessage(messaging, (payload) => {
    console.log("payload", payload);
    toast.info(payload.notification.body);
  });

  // useEffect(() => {
  //   generateToken();
  // }, []);
  return (
    <Router>
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          {/* Education Routes  */}
          <Route path="/education" element={<Education />} />
          <Route path="/eduform/:course" element={<EduForm />} />
          <Route path="/Eadmin" element={<AdminEduLogin />} />
          <Route path="/Edash" element={<AdminEduDash />} />
          {/* Admin Routes  */}
          <Route path="/alogin" element={<JodhpurAdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/acceptedOrder" element={<AcceptedOrder />} />
          <Route path="/admin/shop" element={<Shop />} />
          <Route path="/admin/shop/:shopId" element={<ShopOrders />} />
          {/* User Auth  */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/loginwithpass" element={<LoginwithPassword />} />

          {/* Pages */}

          <Route path="/" element={<HomeLayout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/password" element={<ProfilePassword />} />
          {/* Service */}

          <Route path="/bookRider" element={<BookRider />} />
          <Route path="/bookParcel" element={<BookParcel />} />

          {/* History */}
          <Route path="/riderHistory" element={<RiderHistory />} />
          <Route path="/parcelHistory" element={<ParcelHistory />} />
          <Route path="/riderHistory/:orderId" element={<RiderViewMore />} />
          <Route path="/parcelHistory/:orderId" element={<ParcelViewMore />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
