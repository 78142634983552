import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./features/admin/adminSlice";
import eduAdminSlice from "./features/eduAdmin/eduAdminSlice";
import userSlice from "./features/User/userSlice";

// Load state from local storage
const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem("gotan_redux");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading state from local storage:", error);
    return undefined;
  }
};

// Save state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem("gotan_redux", serializedState);
  } catch (error) {
    console.error("Error saving state to local storage:", error);
  }
};

// Load initial state
const preloadedState = loadState();

// Create store with preloaded state
export const store = configureStore({
  reducer: {
    admin: adminSlice,
    user: userSlice,
    eduAdmin: eduAdminSlice,
  },
  preloadedState,
});

// Subscribe to store changes to save state
store.subscribe(() => {
  saveState(store.getState());
});
