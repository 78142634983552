import firebase from "firebase/compat/app";
import { getMessaging, getToken } from "firebase/messaging";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-HKYA7Ps8XvDkW4FBY6DR25Yk9yOnteQ",
  authDomain: "humans-of-rural-india-d2b22.firebaseapp.com",
  projectId: "humans-of-rural-india-d2b22",
  storageBucket: "humans-of-rural-india-d2b22.appspot.com",
  messagingSenderId: "43638107391",
  appId: "1:43638107391:web:e8be9a0ca85d0a840bf0ca",
  measurementId: "G-VZMKMDSJCF",
};

firebase.initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BK8ZZL0QmKnVGZdIAhHo2CJp9T6Vd1CdppvSLIc15P7GsEv_TTPo8RqbOZm-9_myvNtXl75tFFx6zAkl3W_YEVM",
    });
    return token;
  }
};
