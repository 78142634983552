import React, { useState, useEffect } from "react";
import { EduAdminLoginFetch } from "../../fetch/eduFetch";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/eduAdmin/eduAdminSlice";
import { useNavigate } from "react-router-dom";
function AdminEduLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loggedIn = useSelector((state) => state.eduAdmin.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   console.log(loggedIn);
  useEffect(() => {
    if (loggedIn) {
      navigate("/edash");
    }
  }, [loggedIn]);

  const handleLogin = async () => {
    const responseData = await EduAdminLoginFetch(username, password);
    console.log(responseData);
    if (responseData.success === true) {
      dispatch(login());
      //   console.log(loggedIn);
    } else {
      alert("Incorrect Credentials");
    }
  };
  return (
    <>
      <div>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    </>
  );
}

export default AdminEduLogin;
