import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EduAdminData: {},
  isLoggedIn: false,
};

const eduAdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      const adminData = action.payload;
      state.EduAdminData = adminData;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.EduAdminData = {};
      state.isLoggedIn = false;
    },
  },
});

export const { setAdminData, login, logout } = eduAdminSlice.actions;

export default eduAdminSlice.reducer;
